import { graphql } from 'gatsby';
import Page, { Head } from '../components/page';

export default Page;
export { Head };

export const query = graphql`
  query PageContent($slug: String!) {
    site {
      ...SiteMetadata
    }
    wp {
      ...WpSeo
    }
    wpPage(slug: { eq: $slug }) {
      ...WpPageContent
    }
  }
`
